<template>
    <div v-if="isLoggedIn == true"  class="products_template container">
      <div v-if="productsData.length == 0">
        <ProgressSpinner id="loader" />
      </div>
      
      
          <div v-if="isLoggedIn == true && productsData.length"  class="product_inner">
            <table>
                <thead> 
                    <th>Product ID</th>
                    <th>Product Title</th>
                    <th>Product type</th>
                    <th>Product Handle</th>
                    <th>Product Variants</th>
                </thead>
                <tbody>
                    <tr v-for="(item) in productsData" :key="item.id">
                       <td>{{ item.id }}</td> 
                       <td>{{ item.title }}</td> 
                       <td>{{ item.product_type }}</td> 
                       <td>{{ item.handle }}</td> 
                       <td><a v-bind:href="`/products/${ item.id }/variants`">Variants</a></td> 
                    </tr>
                </tbody>
            </table>
          </div>
          <div v-bind:class="(loader)?'loading':'loaded'"></div> 
    </div>
    <div v-else class="error">
      Please login to check the page <a href="/">Login</a>
    </div>
  </template>
  
  <script>
  import { mapActions,mapGetters } from "vuex";
  import ProgressSpinner from 'primevue/progressspinner';
  export default {
    name: 'products',
     components: {
      ProgressSpinner
     },
      data() {
        return {
          loader:false,
          errors:[],
          productsData:[]
        }
     },
      methods: {
           ...mapActions(["products"]),
           ...mapGetters(["isAuthenticated"]), 
      },
      mounted:async function () {
          this.loader = true;
        if(this.isLoggedIn){
            this.productsData =  await this.products(this.userToken);
            this.loader = false;
        }
      },
      computed: {
        isLoggedIn : function(){
            return this.$store.getters.isAuthenticated
        },
        userToken : function(){
          return this.$store.getters.getUserToken
        },
      },
  
  
  }
  </script>